














































































































































































































































































































































































































































































































.container {
    background-color: #F7F7FA;
    height: 100%;
	font-family: PingFangSC-Regular;
    // height: calc(100vh + 110px);
    .content {
        background-color: #F7F7FA;
        padding-bottom: 1rem;
    }
    .head_right {
        color: #333333;
        font-size: 14px;
    }
}
.nav {
    margin-top: 0.2rem;
    border-top-left-radius: 0.32rem;
    border-top-right-radius: 0.32rem;
    background-color: #ffffff;
    font-family: PingFang SC;
	padding: 0.15rem 0;
    .nav_item1 {
        font-size: 14px;
        padding: 0.15rem 33px;
        color: #8F9EAB;
        // border-bottom: 1px solid #f1f1f1;
        .item {
            margin-left: 30px;
        }
        .item:first-child {
            margin-left: 0;
        }
    }
    .nav_item2 {
        font-size: 14px;
        padding: 0.15rem 33px;
        color: #8F9EAB;
		display: flex;
		justify-content: space-between;
        // border-bottom: 1px solid #f1f1f1;
        .item {
            // margin-left: 20px;
            text-align: center;
        }
        .item:first-child {
            margin-left: 0;
        }
    }
    .nav_item3 {
        font-size: 14px;
        padding: 0.15rem 33px;
        color: #8F9EAB;
        // border-bottom: 1px solid #f1f1f1;
        .item {
            margin-left: 30px;
            text-align: center;
        }
        .item:first-child {
            margin-left: 0;
        }
    }
    .nav_item4 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        padding: 0.15rem 34px;
        color: #8F9EAB;
        .item4_1 {
            border-radius: 4px;
            background-color: #F7F7FA;
            padding: 6px 5px 6px 5px;
            text-align: center;
        }
        .item4_2 {
            border-radius: 4px;
            background-color: #F7F7FA;
            padding: 6px 5px 6px 5px;
            text-align: center;
        }
        .item4_3 {
            border-radius: 4px;
            background-color: #F7F7FA;
            padding: 6px 5px 6px 5px;
            text-align: center;
        }
        .active1 {
            color: #333;
            font-weight: 600;
        }
    }
    .active {
        color: #333333;
        font-size: 14px;
        font-weight: 600;
    }
}
.content {
    .teacher_list1 {
        background-color: #ffffff;
        margin-top: 10px;
        // box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
        font-family: PingFang SC;
		border-top-left-radius: 0.32rem;
		border-top-right-radius: 0.32rem;
		padding: 0.12rem 0.2rem;
        .list-top {
            display: flex;
            // margin-left: 0.7rem;
        }
        .msg_left {
            margin: 10px 0;
            // margin-left: 0.8rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            .img {
                width: 1.2rem;
                height: 1.2rem;
                img {
                    border-radius: 0.5rem;
                    width: 100%;
                    height: 100%;
                }
            }
            .name {
				margin-top: 0.12rem;
                font-size: 14px;
                font-weight: 500;
                color: #333333;
                text-align: center;
            }
            .VIP {
                display: flex;
                align-items: center;
                .img_vip {
                    // width: 0.24rem;
                    // height: 0.24rem;
                    img {
                        width: 0.252399rem;
                        height: 0.2218rem;
                    }
                }
                .vip_number {
					font-family: 'haose';
                    margin-left: 0.05rem;
                    font-size: 0.28rem;
                    color: #2E62FF;
                }
            }
            .bili {
                margin-top: 0.1rem;
                background-color: rgba(46, 98, 255, 0.09);
                padding: 0.08rem 0.28rem 0.06rem 0.28rem;
                border-radius: 0.08rem;
                font-size: 0.24rem;
				transform: scale(0.91);
                color: #2E62FF;
                text-align: center;
				font-weight: 600;
            }
        }
        .msg_right1 {
            margin-top: 0.87rem;
            margin-left: 0.8rem;
            .top {
                display: flex;
                align-items: center;
                .top_left {
                    text-align: left;
                }
                .top_right {
                    margin-left: 0.9rem;
                    text-align: left;
                }
                .txt {
                    text-align: left;
                    color: #6F7A8B;
                    font-size: 0.24rem;
                }
                .left_num {
                    color: #333333;
                    font-size: 0.32rem;
					font-weight: 600;
                }
                .right_num {
                    color: #32BF88;
                    font-size: 0.32rem;
					font-weight: 600;
                }
            }
            .bottom {
                margin-top: 0.4rem;
                display: flex;
                align-items: center;
                // justify-content: space-between;
                .top_left {
                    text-align: left;
                }
                .top_right {
                    text-align: left;
					margin-left: 0.57rem;
                }
                .txt {
                    text-align: left;
                    margin-left: -0.09rem;
                    color: #666666;
                    font-size: 0.24rem;
                    transform: scale(0.83);
                }
                .txt1 {
                    margin-top: 0.15rem;
                    text-align: left;
                    color: #666666;
                    font-size: 0.24rem;
                }
                .left_num {
                    color: #333333;
                    font-size: 0.32rem;
                }
                .right_num {
                    color: #333333;
                    font-size: 0.32rem;
                }
            }
            .bottom1 {
                margin-top: 0.4rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .top_left {
                    text-align: left;
                }
                .top_right {
                    margin-left: 0.6rem;
                    text-align: left;
                }
                .txt {
                    text-align: left;
                    color: #6F7A8B;
                    font-size: 0.24rem;
                }
                .txt1 {
                    margin-top: 0.15rem;
                    text-align: left;
                    color: #666666;
                    font-size: 0.24rem;
                }
                .left_num {
                    color: #333333;
                    font-size: 0.32rem;
                }
                .right_num {
                    color: #333333;
                    font-size: 0.32rem;
                }
            }
            .bottom3 {
                margin-top: 0.4rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .top_left {
                    text-align: left;
                }
                .top_right {
                    text-align: left;
                }
                .txt {
                    text-align: left;
                    color: #2E62FF;
                    font-size: 0.24rem;
                }
                .txt1 {
                    margin-top: 0.15rem;
                    text-align: left;
                    color: #2E62FF;
                    font-size: 0.24rem;
                }
                .left_num {
                    color: #5998fc;
                    font-size: 0.3rem;
                }
                .right_num {
                    color: #d98181;
                    font-size: 0.3rem;
                }
            }
        }
        .bottom_button {
            // margin: 0 0.62rem;
            margin-top: 0.2rem;
            padding: 0.32rem 0;
            border-top: 1px solid #cbcbcb;
            .button {
                display: flex;
                justify-content: space-between;
                .stop {
                    width: 3.26rem;
                    height: 0.8rem;
                    text-align: center;
                    line-height: 0.8rem;
                    border: 0.01rem solid #2E62FF;
                    color: #2E62FF;
                    font-size: 0.32rem;
                    text-align: center;
                    font-weight: 500;
                    // padding: 0.18rem 0.86rem;
                    border-radius: 0.08rem;
                }
                .xiu {
                    width: 3.26rem;
                    height: 0.8rem;
                    text-align: center;
                    line-height: 0.8rem;
                    border: 0.01rem solid #2E62FF;
                    color: #2E62FF;
                    font-size: 0.32rem;
                    text-align: center;
                    font-weight: 500;
                    // padding: 0.18rem 0.86rem;
                    border-radius: 0.08rem;
                }
                .stop1 {
                    width: 3.26rem;
                    height: 0.8rem;
                    text-align: center;
                    line-height: 0.8rem;
                    border: 0.01rem solid #2E62FF;
                    color: #2E62FF;
                    font-size: 0.32rem;
                    text-align: center;
                    font-weight: 500;
                    // padding: 0.18rem 0.86rem;
                    border-radius: 0.08rem;
                }
                .xiu1 {
                   width: 3.26rem;
                   height: 0.8rem;
                   text-align: center;
                   line-height: 0.8rem;
                   border: 0.01rem solid #2E62FF;
                   color: #2E62FF;
                   font-size: 0.32rem;
                   text-align: center;
                   font-weight: 500;
                   // padding: 0.18rem 0.86rem;
                   border-radius: 0.08rem;
                }
                .xiangqing {
                    width: 3.26rem;
                    height: 0.8rem;
                    text-align: center;
                    line-height: 0.8rem;
                    border: 0.01rem solid #2E62FF;
                    color: #2E62FF;
                    font-size: 0.32rem;
                    text-align: center;
                    font-weight: 500;
                    // padding: 0.18rem 0.86rem;
                    border-radius: 0.08rem;
                }
                .left {
                    width: 50%;
                    text-align: center;
                    margin-bottom: 0.38rem;
                    .left-top {
                        color: #333333;
                        font-size: 0.32rem;
                        font-weight: 600;
                    }
                    .left-bottom {
                        color: #6F7A8B;
                        font-size: 0.24rem;
                    }
                }
                .right {
                    width: 50%;
                    text-align: center;
                    margin-bottom: 0.38rem;
                    .left-top {
                        color: #333333;
                        font-size: 0.32rem;
						font-weight: 600;
                    }
                    .left-bottom {
                        color: #6F7A8B;
                        font-size: 0.24rem;
                    }
                }
            }
        }
    }
}
.tan {
    width: 5.6rem;
    height: 3.4rem;
    // background-image: url(../../assets/imgs/tan_bg.png);
    // background-repeat: no-repeat;
    // background-size: 100% 3.6rem;
    .tan_no_txt {
        padding: 0.69rem 0.82rem 0 0.6rem;
        color: #333333;
        font-size: 0.32rem;
        text-align: center;
        font-weight: 500;
    }
    .tan_no_txt1 {
        padding: 0 1.2rem;
		padding-top: 0.63rem;
        color: #333333;
        text-align: left;
        font-weight: 500;
        color: #333333;
		display: flex;
		align-items: center;
		.tan_no_txt1-left {
			color: #333333;
			font-size: 0.32rem;
		}
		.tan_no_txt1-right {
			color: #333333;
			font-size: 0.32rem;
			margin-left: 0.3rem;
		}
    }
    .tan_no_txt2 {
        margin-top: 0.05rem;
		padding: 0 1.2rem;
        color: #333333;
        font-weight: 500;
        text-align: left;
		display: flex;
		align-items: center;
        .tan_no_txt1-left {
        	color: #333333;
        	font-size: 0.32rem;
        }
        .tan_no_txt1-right {
        	color: #333333;
        	font-size: 0.32rem;
        	margin-left: 0.3rem;
        }
    }
    .input {
		margin-top: 0.08rem;
        padding: 0 0.7rem 0 0.72rem;
        color: #333333;
        font-weight: bold;
        input {
            width: 100%;
            border: none;
            border-bottom: 1px solid #D3D9DE;
            padding: 0.04rem 0;
            font-size: 0.32rem;
        }
    }
    .tan_no_button {
		width: 2.62rem;
		height: 0.66rem;
		line-height: 0.66rem;
		text-align: center;
        // margin: 0.47rem 1.2rem 0 1.32rem;
        font-size: 0.28rem;
        color: #FFFFFF;
        background-color: #2E62FF;
        border-radius: 0.08rem;
		margin: 0 auto;
		margin-top: 0.45rem;
    }
	.tishi {
		text-align: center;
		color: #F5465D;
		font-size: 0.24rem;
		transform: scale(0.666);
	}
}
.tan1 {
    width: 5.6rem;
    height: 3.4rem;
    // background-image: url(../../assets/imgs/tan_bg.png);
    // background-repeat: no-repeat;
    // background-size: 100% 3.6rem;
    .tan_no_txt {
        padding: 0.69rem 0.82rem 0 0.6rem;
        color: #333333;
        font-size: 0.32rem;
        text-align: center;
        font-weight: 500;
    }
    .tan_no_txt1 {
        padding: 0.69rem 0.82rem 0 0.72rem;
        color: #333333;
        font-size: 0.24rem;
        text-align: left;
        font-weight: 500;
        color: #333333;
    }
    .tan_no_txt2 {
        margin-top: 0.05rem;
        color: #333333;
        font-size: 0.16rem;
        transform: scale(0.75);
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        text-align: left;
        .right {
            color: #F5465D;
        }
    }
    .input {
		margin-top: 0.08rem;
        padding: 0 0.7rem 0 0.72rem;
        color: #333333;
        font-weight: bold;
        input {
            width: 100%;
            border: none;
            border-bottom: 1px solid #D3D9DE;
            padding: 0.04rem 0;
            font-size: 0.32rem;
        }
    }
    .tan_no_button {
        margin: 0.47rem 1.2rem 0 1.32rem;
        padding: 0.1rem 0.36rem 0.08rem 0.36rem;
        font-size: 0.28rem;
        color: #FFFFFF;
        background-color: #2E62FF;
        text-align: center;
        border-radius: 0.08rem;
    }
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;