.container {
  background-color: #F7F7FA;
  height: 100%;
  font-family: PingFangSC-Regular;
}
.container .content {
  background-color: #F7F7FA;
  padding-bottom: 1rem;
}
.container .head_right {
  color: #333333;
  font-size: 14px;
}
.nav {
  margin-top: 0.2rem;
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
  background-color: #ffffff;
  font-family: PingFang SC;
  padding: 0.15rem 0;
}
.nav .nav_item1 {
  font-size: 14px;
  padding: 0.15rem 33px;
  color: #8F9EAB;
}
.nav .nav_item1 .item {
  margin-left: 30px;
}
.nav .nav_item1 .item:first-child {
  margin-left: 0;
}
.nav .nav_item2 {
  font-size: 14px;
  padding: 0.15rem 33px;
  color: #8F9EAB;
  display: flex;
  justify-content: space-between;
}
.nav .nav_item2 .item {
  text-align: center;
}
.nav .nav_item2 .item:first-child {
  margin-left: 0;
}
.nav .nav_item3 {
  font-size: 14px;
  padding: 0.15rem 33px;
  color: #8F9EAB;
}
.nav .nav_item3 .item {
  margin-left: 30px;
  text-align: center;
}
.nav .nav_item3 .item:first-child {
  margin-left: 0;
}
.nav .nav_item4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding: 0.15rem 34px;
  color: #8F9EAB;
}
.nav .nav_item4 .item4_1 {
  border-radius: 4px;
  background-color: #F7F7FA;
  padding: 6px 5px 6px 5px;
  text-align: center;
}
.nav .nav_item4 .item4_2 {
  border-radius: 4px;
  background-color: #F7F7FA;
  padding: 6px 5px 6px 5px;
  text-align: center;
}
.nav .nav_item4 .item4_3 {
  border-radius: 4px;
  background-color: #F7F7FA;
  padding: 6px 5px 6px 5px;
  text-align: center;
}
.nav .nav_item4 .active1 {
  color: #333;
  font-weight: 600;
}
.nav .active {
  color: #333333;
  font-size: 14px;
  font-weight: 600;
}
.content .teacher_list1 {
  background-color: #ffffff;
  margin-top: 10px;
  font-family: PingFang SC;
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
  padding: 0.12rem 0.2rem;
}
.content .teacher_list1 .list-top {
  display: flex;
}
.content .teacher_list1 .msg_left {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content .teacher_list1 .msg_left .img {
  width: 1.2rem;
  height: 1.2rem;
}
.content .teacher_list1 .msg_left .img img {
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
}
.content .teacher_list1 .msg_left .name {
  margin-top: 0.12rem;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
.content .teacher_list1 .msg_left .VIP {
  display: flex;
  align-items: center;
}
.content .teacher_list1 .msg_left .VIP .img_vip img {
  width: 0.252399rem;
  height: 0.2218rem;
}
.content .teacher_list1 .msg_left .VIP .vip_number {
  font-family: 'haose';
  margin-left: 0.05rem;
  font-size: 0.28rem;
  color: #2E62FF;
}
.content .teacher_list1 .msg_left .bili {
  margin-top: 0.1rem;
  background-color: rgba(46, 98, 255, 0.09);
  padding: 0.08rem 0.28rem 0.06rem 0.28rem;
  border-radius: 0.08rem;
  font-size: 0.24rem;
  transform: scale(0.91);
  color: #2E62FF;
  text-align: center;
  font-weight: 600;
}
.content .teacher_list1 .msg_right1 {
  margin-top: 0.87rem;
  margin-left: 0.8rem;
}
.content .teacher_list1 .msg_right1 .top {
  display: flex;
  align-items: center;
}
.content .teacher_list1 .msg_right1 .top .top_left {
  text-align: left;
}
.content .teacher_list1 .msg_right1 .top .top_right {
  margin-left: 0.9rem;
  text-align: left;
}
.content .teacher_list1 .msg_right1 .top .txt {
  text-align: left;
  color: #6F7A8B;
  font-size: 0.24rem;
}
.content .teacher_list1 .msg_right1 .top .left_num {
  color: #333333;
  font-size: 0.32rem;
  font-weight: 600;
}
.content .teacher_list1 .msg_right1 .top .right_num {
  color: #32BF88;
  font-size: 0.32rem;
  font-weight: 600;
}
.content .teacher_list1 .msg_right1 .bottom {
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
}
.content .teacher_list1 .msg_right1 .bottom .top_left {
  text-align: left;
}
.content .teacher_list1 .msg_right1 .bottom .top_right {
  text-align: left;
  margin-left: 0.57rem;
}
.content .teacher_list1 .msg_right1 .bottom .txt {
  text-align: left;
  margin-left: -0.09rem;
  color: #666666;
  font-size: 0.24rem;
  transform: scale(0.83);
}
.content .teacher_list1 .msg_right1 .bottom .txt1 {
  margin-top: 0.15rem;
  text-align: left;
  color: #666666;
  font-size: 0.24rem;
}
.content .teacher_list1 .msg_right1 .bottom .left_num {
  color: #333333;
  font-size: 0.32rem;
}
.content .teacher_list1 .msg_right1 .bottom .right_num {
  color: #333333;
  font-size: 0.32rem;
}
.content .teacher_list1 .msg_right1 .bottom1 {
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content .teacher_list1 .msg_right1 .bottom1 .top_left {
  text-align: left;
}
.content .teacher_list1 .msg_right1 .bottom1 .top_right {
  margin-left: 0.6rem;
  text-align: left;
}
.content .teacher_list1 .msg_right1 .bottom1 .txt {
  text-align: left;
  color: #6F7A8B;
  font-size: 0.24rem;
}
.content .teacher_list1 .msg_right1 .bottom1 .txt1 {
  margin-top: 0.15rem;
  text-align: left;
  color: #666666;
  font-size: 0.24rem;
}
.content .teacher_list1 .msg_right1 .bottom1 .left_num {
  color: #333333;
  font-size: 0.32rem;
}
.content .teacher_list1 .msg_right1 .bottom1 .right_num {
  color: #333333;
  font-size: 0.32rem;
}
.content .teacher_list1 .msg_right1 .bottom3 {
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content .teacher_list1 .msg_right1 .bottom3 .top_left {
  text-align: left;
}
.content .teacher_list1 .msg_right1 .bottom3 .top_right {
  text-align: left;
}
.content .teacher_list1 .msg_right1 .bottom3 .txt {
  text-align: left;
  color: #2E62FF;
  font-size: 0.24rem;
}
.content .teacher_list1 .msg_right1 .bottom3 .txt1 {
  margin-top: 0.15rem;
  text-align: left;
  color: #2E62FF;
  font-size: 0.24rem;
}
.content .teacher_list1 .msg_right1 .bottom3 .left_num {
  color: #5998fc;
  font-size: 0.3rem;
}
.content .teacher_list1 .msg_right1 .bottom3 .right_num {
  color: #d98181;
  font-size: 0.3rem;
}
.content .teacher_list1 .bottom_button {
  margin-top: 0.2rem;
  padding: 0.32rem 0;
  border-top: 1px solid #cbcbcb;
}
.content .teacher_list1 .bottom_button .button {
  display: flex;
  justify-content: space-between;
}
.content .teacher_list1 .bottom_button .button .stop {
  width: 3.26rem;
  height: 0.8rem;
  line-height: 0.8rem;
  border: 0.01rem solid #2E62FF;
  color: #2E62FF;
  font-size: 0.32rem;
  text-align: center;
  font-weight: 500;
  border-radius: 0.08rem;
}
.content .teacher_list1 .bottom_button .button .xiu {
  width: 3.26rem;
  height: 0.8rem;
  line-height: 0.8rem;
  border: 0.01rem solid #2E62FF;
  color: #2E62FF;
  font-size: 0.32rem;
  text-align: center;
  font-weight: 500;
  border-radius: 0.08rem;
}
.content .teacher_list1 .bottom_button .button .stop1 {
  width: 3.26rem;
  height: 0.8rem;
  line-height: 0.8rem;
  border: 0.01rem solid #2E62FF;
  color: #2E62FF;
  font-size: 0.32rem;
  text-align: center;
  font-weight: 500;
  border-radius: 0.08rem;
}
.content .teacher_list1 .bottom_button .button .xiu1 {
  width: 3.26rem;
  height: 0.8rem;
  line-height: 0.8rem;
  border: 0.01rem solid #2E62FF;
  color: #2E62FF;
  font-size: 0.32rem;
  text-align: center;
  font-weight: 500;
  border-radius: 0.08rem;
}
.content .teacher_list1 .bottom_button .button .xiangqing {
  width: 3.26rem;
  height: 0.8rem;
  line-height: 0.8rem;
  border: 0.01rem solid #2E62FF;
  color: #2E62FF;
  font-size: 0.32rem;
  text-align: center;
  font-weight: 500;
  border-radius: 0.08rem;
}
.content .teacher_list1 .bottom_button .button .left {
  width: 50%;
  text-align: center;
  margin-bottom: 0.38rem;
}
.content .teacher_list1 .bottom_button .button .left .left-top {
  color: #333333;
  font-size: 0.32rem;
  font-weight: 600;
}
.content .teacher_list1 .bottom_button .button .left .left-bottom {
  color: #6F7A8B;
  font-size: 0.24rem;
}
.content .teacher_list1 .bottom_button .button .right {
  width: 50%;
  text-align: center;
  margin-bottom: 0.38rem;
}
.content .teacher_list1 .bottom_button .button .right .left-top {
  color: #333333;
  font-size: 0.32rem;
  font-weight: 600;
}
.content .teacher_list1 .bottom_button .button .right .left-bottom {
  color: #6F7A8B;
  font-size: 0.24rem;
}
.tan {
  width: 5.6rem;
  height: 3.4rem;
}
.tan .tan_no_txt {
  padding: 0.69rem 0.82rem 0 0.6rem;
  color: #333333;
  font-size: 0.32rem;
  text-align: center;
  font-weight: 500;
}
.tan .tan_no_txt1 {
  padding: 0 1.2rem;
  padding-top: 0.63rem;
  text-align: left;
  font-weight: 500;
  color: #333333;
  display: flex;
  align-items: center;
}
.tan .tan_no_txt1 .tan_no_txt1-left {
  color: #333333;
  font-size: 0.32rem;
}
.tan .tan_no_txt1 .tan_no_txt1-right {
  color: #333333;
  font-size: 0.32rem;
  margin-left: 0.3rem;
}
.tan .tan_no_txt2 {
  margin-top: 0.05rem;
  padding: 0 1.2rem;
  color: #333333;
  font-weight: 500;
  text-align: left;
  display: flex;
  align-items: center;
}
.tan .tan_no_txt2 .tan_no_txt1-left {
  color: #333333;
  font-size: 0.32rem;
}
.tan .tan_no_txt2 .tan_no_txt1-right {
  color: #333333;
  font-size: 0.32rem;
  margin-left: 0.3rem;
}
.tan .input {
  margin-top: 0.08rem;
  padding: 0 0.7rem 0 0.72rem;
  color: #333333;
  font-weight: bold;
}
.tan .input input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #D3D9DE;
  padding: 0.04rem 0;
  font-size: 0.32rem;
}
.tan .tan_no_button {
  width: 2.62rem;
  height: 0.66rem;
  line-height: 0.66rem;
  text-align: center;
  font-size: 0.28rem;
  color: #FFFFFF;
  background-color: #2E62FF;
  border-radius: 0.08rem;
  margin: 0 auto;
  margin-top: 0.45rem;
}
.tan .tishi {
  text-align: center;
  color: #F5465D;
  font-size: 0.24rem;
  transform: scale(0.666);
}
.tan1 {
  width: 5.6rem;
  height: 3.4rem;
}
.tan1 .tan_no_txt {
  padding: 0.69rem 0.82rem 0 0.6rem;
  color: #333333;
  font-size: 0.32rem;
  text-align: center;
  font-weight: 500;
}
.tan1 .tan_no_txt1 {
  padding: 0.69rem 0.82rem 0 0.72rem;
  font-size: 0.24rem;
  text-align: left;
  font-weight: 500;
  color: #333333;
}
.tan1 .tan_no_txt2 {
  margin-top: 0.05rem;
  color: #333333;
  font-size: 0.16rem;
  transform: scale(0.75);
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  text-align: left;
}
.tan1 .tan_no_txt2 .right {
  color: #F5465D;
}
.tan1 .input {
  margin-top: 0.08rem;
  padding: 0 0.7rem 0 0.72rem;
  color: #333333;
  font-weight: bold;
}
.tan1 .input input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #D3D9DE;
  padding: 0.04rem 0;
  font-size: 0.32rem;
}
.tan1 .tan_no_button {
  margin: 0.47rem 1.2rem 0 1.32rem;
  padding: 0.1rem 0.36rem 0.08rem 0.36rem;
  font-size: 0.28rem;
  color: #FFFFFF;
  background-color: #2E62FF;
  text-align: center;
  border-radius: 0.08rem;
}
